
/**
 * Checks if the route is a data compass route with compass group and indicator segment
 * 
 * e.g.
 * /christchurch/data/ecology/biodiversity/
 * /christchurch/data/energy/roof-solar-panels/
 * /christchurch/data/ecology/bird-count/
 */
export const isRouteDataCompassWithIndicator = (pathname: string): boolean => {
    return /\/\w+\/data\/(ecology|energy|economy|urban|architecture)\/[a-z\-]+\//gm.test(pathname)
}
