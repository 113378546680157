'use client'

import React from 'react'
import { Button } from '@/components/UI/Button/Button'
import { usePathname } from 'next/navigation'
import { isRouteDataCompassWithIndicator } from '@/utils/isRouteDataCompassWithIndicator'
import classNames from 'classnames'
import ChevronSVG from '@/components/UI/Accordion/chevron.svg'
import Link from 'next/link'
import ExternalLinkSVG from '@/components/UI/Button/external-link.svg'
import { MenuDataLink } from '@/types'

const SITE_TITLE = 'Transition tools'

function HomePageLogoLink({ closeMenu }: { closeMenu: () => void }) {
    // const isDataCompass = false

    return (
        <Link
            href="/"
            aria-label="Home page"
            onClick={ closeMenu }
            className="fill-current group flex flex-row items-center gap-2 hover:fill-ecology hover:text-ecology"
        >
            {/* @TODO Polish - Update the SVG rings based on the current page, living cities vs data compass colours */}
            {/* <svg className="w-[32px] h-[32px]" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={ classNames(
                    "fill-white",
                    isDataCompass && "fill-ecology"
                ) } id="outer" fillRule="evenodd" clipRule="evenodd" d="M36.0002 8.39995C20.7571 8.39995 8.4002 20.7569 8.4002 36C8.4002 51.243 20.7571 63.5999 36.0002 63.5999C51.2433 63.5999 63.6002 51.243 63.6002 36C63.6002 20.7569 51.2433 8.39995 36.0002 8.39995ZM1.2002 36C1.2002 16.7804 16.7807 1.19995 36.0002 1.19995C55.2197 1.19995 70.8002 16.7804 70.8002 36C70.8002 55.2195 55.2197 70.7999 36.0002 70.7999C16.7807 70.7999 1.2002 55.2195 1.2002 36Z" />
                <path className={ classNames(
                    "fill-white opacity-80",
                    isDataCompass && "fill-energy"
                ) } id="middle" fillRule="evenodd" clipRule="evenodd" d="M35.9998 18C26.0587 18 17.9998 26.0589 17.9998 36C17.9998 45.9412 26.0587 54 35.9998 54C45.9409 54 53.9998 45.9412 53.9998 36C53.9998 26.0589 45.9409 18 35.9998 18ZM10.7998 36C10.7998 22.0825 22.0822 10.8 35.9998 10.8C49.9174 10.8 61.1998 22.0825 61.1998 36C61.1998 49.9176 49.9174 61.2 35.9998 61.2C22.0822 61.2 10.7998 49.9176 10.7998 36Z" />
                <path className={ classNames(
                    "fill-white opacity-60",
                    isDataCompass && "fill-economy"
                ) } id="inner" fillRule="evenodd" clipRule="evenodd" d="M35.9999 27.5999C31.3607 27.5999 27.5999 31.3607 27.5999 35.9999C27.5999 40.6391 31.3607 44.3999 35.9999 44.3999C40.6391 44.3999 44.3999 40.6391 44.3999 35.9999C44.3999 31.3607 40.6391 27.5999 35.9999 27.5999ZM20.3999 35.9999C20.3999 27.3843 27.3843 20.3999 35.9999 20.3999C44.6155 20.3999 51.5999 27.3843 51.5999 35.9999C51.5999 44.6155 44.6155 51.5999 35.9999 51.5999C27.3843 51.5999 20.3999 44.6155 20.3999 35.9999Z" />
            </svg> */}

            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <path fillRule="evenodd" clipRule="evenodd" d="M16 4.5C9.64873 4.5 4.5 9.64873 4.5 16C4.5 22.3513 9.64873 27.5 16 27.5C22.3513 27.5 27.5 22.3513 27.5 16C27.5 9.64873 22.3513 4.5 16 4.5ZM1.5 16C1.5 7.99187 7.99187 1.5 16 1.5C24.0081 1.5 30.5 7.99187 30.5 16C30.5 24.0081 24.0081 30.5 16 30.5C7.99187 30.5 1.5 24.0081 1.5 16Z" />
                <path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M16 8.5C11.8579 8.5 8.5 11.8579 8.5 16C8.5 20.1421 11.8579 23.5 16 23.5C20.1421 23.5 23.5 20.1421 23.5 16C23.5 11.8579 20.1421 8.5 16 8.5ZM5.5 16C5.5 10.201 10.201 5.5 16 5.5C21.799 5.5 26.5 10.201 26.5 16C26.5 21.799 21.799 26.5 16 26.5C10.201 26.5 5.5 21.799 5.5 16Z" />
                <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M16 12.5C14.067 12.5 12.5 14.067 12.5 16C12.5 17.933 14.067 19.5 16 19.5C17.933 19.5 19.5 17.933 19.5 16C19.5 14.067 17.933 12.5 16 12.5ZM9.5 16C9.5 12.4101 12.4101 9.5 16 9.5C19.5899 9.5 22.5 12.4101 22.5 16C22.5 19.5899 19.5899 22.5 16 22.5C12.4101 22.5 9.5 19.5899 9.5 16Z" />
            </svg>

            <span className="body-2 text-shadow border-b border-transparent font-extrabold opacity-85 group-hover:border-ecology">{SITE_TITLE}</span>
        </Link>
    )
}

function isUrlActive(url: string, pathname: string) {
    return pathname === url || url.includes("/data/") && isRouteDataCompassWithIndicator(pathname)
}

interface HeaderProps {
    links: MenuDataLink[]
}

export const Header = ({ links = [] }: HeaderProps) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const pathname = usePathname()

    React.useEffect(() => {
        const body = document.querySelector('body')

        if (isMenuOpen) {
            body.classList.add("no-body-scroll")
        } else {
            body.classList.remove("no-body-scroll")
        }
    }, [isMenuOpen])

    return (
        <nav className="container relative z-20 lg:h-[64px]" aria-label="Global">
            {/* Mobile hamburger */}
            <div className="z-20 flex flex-row items-center justify-between py-4 lg:hidden">
                <HomePageLogoLink closeMenu={ () => setIsMenuOpen(false) } />

                <button className="group -m-2 flex items-center justify-center p-2" onClick={ () => setIsMenuOpen(!isMenuOpen) }>
                    <span className="flex size-[32px] flex-col items-center justify-center">
                        <span className={ classNames("hamburger block group-hover:bg-ecology transition-all duration-300 ease-out h-0.5 w-5 rounded-sm", isMenuOpen ? "rotate-45 translate-y-1" : "-translate-y-0.5") } />
                        <span className={ classNames("hamburger block group-hover:bg-ecology transition-all duration-300 ease-out h-0.5 w-5 rounded-sm my-0.5", isMenuOpen ? "opacity-0" : "opacity-100") } />
                        <span className={ classNames("hamburger block group-hover:bg-ecology transition-all duration-300 ease-out h-0.5 w-5 rounded-sm", isMenuOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5") } />  
                    </span>
                </button>
            </div>

            {/* Mobile menu */}
            <div className={ classNames("fixed top-0 bottom-0 bg-current w-full transition-[left] -z-[1] lg:hidden", isMenuOpen ? "left-0 lg:hidden" : "-left-full lg:hidden transition-none") }>
                <ul className="container flex flex-col gap-4 p-4 pt-20">
                    {links.map(({ id, value }) => {
                        const {url, open_in_new_tab, label} = value
                        // Account for selected indicators in the URL e.g. /christchurch/data/ecology/biodiversity/
                        const isActive = isUrlActive(url, pathname)

                        return (
                            <li key={ id }>
                                <Link
                                    className={ classNames(
                                        "py-4 border-b link text-current text-lg leading-snug font-bold justify-between items-center gap-1 flex hover:text-ecology hover:stroke-ecology",
                                        isActive && "text-ecology border-b-ecology stroke-ecology",
                                        // Hides the link from tabbing when the menu is closed
                                        isMenuOpen ? "visible" : "invisible"
                                    ) }
                                    href={ url }
                                    target={ open_in_new_tab ? "_blank" : undefined }
                                    rel={ open_in_new_tab ? "nofollow noreferrer" : undefined }
                                    onClick={ () => setIsMenuOpen(false) }
                                >
                                    <span className="flex w-full flex-row items-center justify-between gap-4">
                                        <span className="body-2 text-shadow">{label}</span>

                                        <span className="size-[20px]">
                                            {open_in_new_tab ? (
                                                <ExternalLinkSVG className="stroke-current" />
                                            ) : (
                                                <ChevronSVG className="stroke-current rotate-[270deg]" />
                                            )}
                                        </span>
                                    </span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>

            {/* Desktop links */}
            <div className="hidden flex-row items-center gap-12 lg:flex lg:h-full">
                <HomePageLogoLink closeMenu={ () => setIsMenuOpen(false) } />

                <ul className="flex flex-row items-center gap-12">
                    {links.map(({ id, value }) => {
                        const {url, open_in_new_tab, label} = value
                        // Account for selected indicators in the URL e.g. /christchurch/data/ecology/biodiversity/
                        const isActive = isUrlActive(url, pathname)

                        // className={ isActive && "text-ecology text-underline" } 
                        return (
                            <li key={ id }>
                                <Button isBold={ isActive } onClick={ url } openInNewTab={ open_in_new_tab }>
                                    {label}
                                </Button>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </nav>
    )
}
