import(/* webpackMode: "eager" */ "/frontend/nextjs/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/frontend/nextjs/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/frontend/nextjs/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/frontend/nextjs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/frontend/nextjs/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"weight\":[\"800\",\"700\",\"600\",\"500\",\"400\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-PlusJakartaSans\"}],\"variableName\":\"plusJakartaSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/frontend/nextjs/src/components/UI/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/frontend/nextjs/src/components/UI/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/frontend/nextjs/src/styles/globals.scss");
