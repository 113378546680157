'use client'

import Link from 'next/link'
import cn from 'classnames'
import React, { ButtonHTMLAttributes } from 'react'
import ExternalLinkSVG from './external-link.svg'

interface ButtonProps {
    children: React.ReactNode
    type?: ButtonHTMLAttributes<{}>['type']
    onClick?: string | React.MouseEventHandler<HTMLButtonElement>;
    className?: string
    id?: string
    openInNewTab?: boolean
    ariaLabel?: string

    withBorder?: boolean
    isBold?: boolean
}

export function Button(props: ButtonProps) {
    const {
        type = 'button',
        children,
        className,
        onClick,
        // Variations
        withBorder,
        isBold,
        openInNewTab, // Also adds the icon
        ariaLabel,
    } = props

    const isLink = typeof onClick === 'string'

    const classNames = cn(
        'link text-lg leading-snug justify-start items-center gap-1 inline-flex hover:text-ecology',
        withBorder ? 'border border-current px-4 py-2 rounded-lg hover:border-ecology' : 'border-b border-b-transparent hover:border-b-ecology',
        isBold ? 'font-bold' : 'font-normal',
        className
    )

    // @TODO Could automatically check URLs to see if they are external or not
    if (isLink) {
        return (
            <Link
                className={ classNames }
                href={ onClick }
                passHref
                target={ openInNewTab ? '_blank' : undefined }
                rel={ openInNewTab ? 'noreferrer nofollow' : undefined }
                aria-label={ ariaLabel }
            >
                {children}
                {openInNewTab && <ExternalLinkSVG />}
            </Link>
        )
    }

    return (
        <button type={ type } className={ classNames } onClick={ onClick } aria-label={ ariaLabel }>
            {children}
        </button>
    )
}
