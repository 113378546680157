'use client'

import React from 'react'
import Image from 'next/image'
import { Button } from '../Button/Button'
import { useMediaQuery } from '@mantine/hooks'
import { MenuDataLink } from '@/types'

const useFooterHeight = (): number => {
    const [footerHeight, setFooterHeight] = React.useState(0)

    React.useEffect(() => {
        const updateFooterHeight = () => {
            const footer = document.querySelector('footer')
            if (footer) {
                setFooterHeight(footer.offsetHeight)
            }
        }

        updateFooterHeight()

        addEventListener('resize', updateFooterHeight)

        return () => {
            removeEventListener('resize', updateFooterHeight)
        }
    }, [])

    return footerHeight
}

interface FooterProps {
    links: MenuDataLink[]
}

// Nice to have - "reveal" parallax effect on footer when scrolling
export const Footer = ({ links = [] }: FooterProps) => {
    const footerHeight = useFooterHeight()
    const applyStickyFooter = useMediaQuery('(min-width: 1024px)', false)

    // Adds margin to the main element so the footer has a reveal effect on 768+ screens
    React.useEffect(() => {
        const main = document.getElementById("main")

        if (!main) {
            return
        }

        // style={{ marginBottom: applyStickyFooter ? footerHeight : '0px' }}
        main.style.marginBottom = applyStickyFooter ? `${ footerHeight }px` : '0px'
    }, [footerHeight, applyStickyFooter])

    return (
        <footer className="relative inset-x-0 bottom-0 z-[1] flex flex-col gap-10 overflow-hidden bg-darkGreen-100 p-10 text-white md:gap-20 md:p-20 lg:fixed">
            <div className="pointer-events-none absolute inset-x-0 bottom-0 z-0 bg-gradient-to-b from-darkGreen-100 to-darkGreen-100 opacity-80">
                <Image src="/images/footer-background.jpeg" width={ 2304 } height={ 4096 } alt="" className="object-cover opacity-50" />
            </div>

            {/* Gradient to help boost contrast with the links on the left at mobile, and on the right at tablet up */}
            <div className="absolute inset-y-0 left-0 z-0 h-full w-1/2 bg-gradient-to-r from-darkGreen-100 to-darkGreen-100/0 opacity-80 md:left-auto md:right-0 md:w-2/5 md:bg-gradient-to-l"/>

            <div className="z-10 flex flex-col gap-10 md:flex-row md:justify-between">
                <h2>
                    <span className="block">ORA</span>
                    <span className="block text-ecology">regenerative</span>
                    <span className="block">lab</span>
                </h2>

                <div className="flex flex-col gap-10">
                    <ul className="flex flex-col gap-4">
                        {links.map(({ id, value }) => {
                            const { url, label, open_in_new_tab } = value

                            return (
                                <li key={ id }>
                                    <Button isBold onClick={ url } openInNewTab={ open_in_new_tab }>{label}</Button>
                                </li>
                            )
                        })}
                    </ul>

                    <p className="flex flex-col items-start">
                        <span className="font-bold">Contact</span>
                        <Button onClick="mailto:amanda.yates@aut.ac.nz">amanda.yates@aut.ac.nz</Button>
                    </p>
                </div>
            </div>

            <p className="body-2 z-10 text-right text-white">© ORA 2024</p>
        </footer>
    )
}
